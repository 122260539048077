import React from "react"
import Stack from '@mui/material/Stack'
import Typography from "@mui/material/Typography"
import { SlideIn } from "./utils/ExampleUsage"

export default function Info({mode}){
    return (
        <SlideIn>
            <Stack
                direction="column"
                spacing={2}
                sx={{
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    textAlign: 'left'
                }}
            >
                <Typography 
                    variant={'h2'} 
                    sx={{
                        fontWeight: 700, 
                        fontSize: {sm: '60px', xs: '46px'}
                    }}
                >
                    <a 
                        href="/" 
                        alt='Home' 
                        style={{
                            color: mode === 'dark' ? '#fff' : '#000'
                        }}
                    >
                        Naguib Heiba
                    </a>
                </Typography>
                <Typography variant="h5">
                    Software Engineer & Digital Health Researcher
                    </Typography>
                <Typography 
                    variant="body1" 
                    sx={{
                        color:  mode === 'dark' ? '#94A3B8' : '#727783'
                    }}
                >
                    Transforming digital experiences with passion and precision, crafting accessible, clean, and user-centric web applications that delight and empower.
                </Typography>
            </Stack>
        </SlideIn>
    )
}