export const experience = [
    {
      id: 1,
      start: 'Jan 2022',
      end: 'PRESENT',
      title: 'Researcher',
      Department: 'Digital Health',
      Company: 'Fraunhofer FIT',
      location: 'Sankt Augustin - Germany',
      stack: ['JavaScript, React, Node.js, HTML & CSS, PostgreSQL, GraphQL, REST, fastify, FHIR, Python, scikit-learn, Docker, Git, WCAG2.2'],
      shortDescription:
        'Build and maintained e-health web applications and services. Building Machine Learning components. using pre-trained and fine tuned large language models in web applications.',
      link: 'https://www.fit.fraunhofer.de/',
      projects: ['ESCAPE, INGE, FrühstArt, CAREPATH, genomDE, Accessibility Evaluation'],
      logo: './fit.svg'
    },
    {
      id: 2,
      start: 'Feb 2020',
      end: 'Dec 2021',
      title: 'Project Engineer and Product Owner',
      Department: 'Software and Business Unit',
      Company: 'Conbility GmbH',
      location: 'Aachen - Germany',
      stack: ['JavaScript, EJS, Node.js, HTML & CSS, MongoDB, REST, express, Azure, Git, .NET'],
      shortDescription:
        'Worked on different projects since I started with Conbility in February 2020. In software development, I participated in developing desktop applications based on .NET framework, mainly writing front-end components and successfully led the development of web applications using the MEN Stack.',
      link: 'https://www.conbility.de/en/',
      projects: ['KMUsense 4.0, meet2connect, OPLYSIS, Cost Optimization Consulting Projects'],
       logo: 'Conbility.png'
    },
    {
      id: 3,
      start: 'May 2019',
      end: 'Jan 2020',
      title: 'Research Student Assistant',
      Department: 'Software and Business Unit',
      Company: 'AZL Aachen GmbH',
      location: 'Aachen - Germany',
      stack: ['MATLAB, Simulink, C#'],
      shortDescription:
        'Processing and analyzing various sensor data using MATLAB, Simulink and C#. Developing various concepts for wireless data acquisition technology.',
      link: 'https://azl-aachen-gmbh.de/',
      projects: ['KMUsense 4.0'],
      logo: 'azl_logo.png'
    },
  ]