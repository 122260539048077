import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import { SlideIn } from './utils/ExampleUsage'


export default function AboutMe({mode}) {
    return (
        <Box 
            sx={{
                minHeight: '100%', 
                padding: '5px',
                textWrap: 'wrap'
            }}
        >
            <SlideIn direction='right'>
                <Stack 
                    direction={'column'} 
                    spacing={2}
                >
                    <Typography 
                        variant='h6' 
                        sx={{
                            fontWeight: 700, 
                            textTransform: 'uppercase', 
                            color: mode === 'dark' ? '#fff' : '#000',
                            display: {lg: 'none', xs: 'block'},
                            paddingBottom: 6
                        }}
                    >
                        About
                    </Typography>
                        <Typography 
                            variant='about' 
                            sx={{
                                color:  mode === 'dark' ? '#94A3B8' : '#727783', 
                                fontWeight: 500,
                            }}
                        >
                            In 2017, I decided to further my studies in Germany by pursuing a master's degree in Industrial Informatics from <Link 
                                href='https://www.hs-emden-leer.de/studierende/fachbereiche/technik/studiengaenge/industrial-informatics-master' 
                                color = {mode === 'dark' ? '#fff' : '#000'}
                                sx={{
                                ':hover': {
                                        color: mode === 'dark' ? '#5EEAD4' : '#ff5733', 
                                    }
                                }}
                                underline="none"
                            >Hochschule Emden/Leer</Link>. This choice led to a rewarding path in both technology and research. During my time as a student, I worked as a student assistant at <Link 
                                href='https://azl-aachen-gmbh.de/en/home/'
                                color = {mode === 'dark' ? '#fff' : '#000'}
                                sx={{
                                ':hover': {
                                        color: mode === 'dark' ? '#5EEAD4' : '#ff5733', 
                                    }
                                }}
                                underline="none"
                            >AZL Aachen GmbH</Link>, which gave me hands-on experience, and later, I completed my master’s thesis at the <Link href='https://www.ipt.fraunhofer.de/' 
                            color = {mode === 'dark' ? '#fff' : '#000'}
                            sx={{
                            ':hover': {
                                    color: mode === 'dark' ? '#5EEAD4' : '#ff5733', 
                                }
                            }}
                            underline="none"
                            >Fraunhofer IPT</Link>.
                        </Typography>
                        <Typography 
                            variant='about' 
                            sx={{
                                color:  mode === 'dark' ? '#94A3B8' : '#727783', 
                                fontWeight: 500
                            }}
                        >
                            After graduating, I moved into industry and worked as a Project Engineer and Product Owner at 
                            <Link
                                href='https://www.conbility.de/en/' 
                                color = {mode === 'dark' ? '#fff' : '#000'}
                                sx={{
                                ':hover': {
                                        color: mode === 'dark' ? '#5EEAD4' : '#ff5733', 
                                    }
                                }}
                                underline="none"
                            > Conbility GmbH 
                            </Link> from the beginning of 2020 till the end of 2021. This role helped me strengthen my skills in project management and product development, giving me a strong foundation for my future career.
                        </Typography>
                    <Typography 
                        variant='about' 
                        sx={{
                            color: mode === 'dark' ? '#94A3B8' : '#727783', 
                            fontWeight: 500,
                        }}
                        >
                        In 2022, I returned to academia, joining 
                        <Link
                            href='https://www.fit.fraunhofer.de/' 
                            color = {mode === 'dark' ? '#fff' : '#000'}
                            sx={{
                            ':hover': {
                                    color: mode === 'dark' ? '#5EEAD4' : '#ff5733', 
                                }
                            }}
                            underline="none"
                            > Fraunhofer FIT</Link> as a researcher and starting my PhD in Computer Science at 
                            <Link 
                                href='https://www.rwth-aachen.de/go/id/a/?lidx=1' 
                                color = {mode === 'dark' ? '#fff' : '#000'}
                                sx={{
                                ':hover': {
                                        color: mode === 'dark' ? '#5EEAD4' : '#ff5733', 
                                    }
                                }}
                                underline="none"
                                > RWTH Aachen University</Link>. For the last three years, my focus has been on digital health, where I’ve gained significant experience in eHealth solutions and healthcare standards.
                    </Typography>
                    <Typography variant='about' sx={{color:  mode === 'dark' ? '#94A3B8' : '#727783', fontWeight: 500,}}> 
                        Throughout my career, I’ve always had a strong interest in web development, especially in creating secure, interoperable, user-friendly, responsive, and accessible interfaces. Combining this with my research in digital health puts me in a good position to contribute to innovative technology in healthcare.
                    </Typography>
                    <Typography variant='about' sx={{color:  mode === 'dark' ? '#94A3B8' : '#727783', fontWeight: 500,}}>
                        My varied experience, spanning both industry and academia, helps me bring a well-rounded approach to research and development. I’m passionate about using my skills to make meaningful contributions to the intersection of technology and healthcare.
                    </Typography>
                </Stack>
            </SlideIn>
        </Box>
    )
}