import { createTheme } from '@mui/material/styles';

// Define common colors with grades
const primaryColors = {
  50: '#e3f2fd',
  100: '#bbdefb',
  200: '#90caf9',
  300: '#64b5f6',
  400: '#42a5f5',
  500: '#2196f3',
  600: '#1e88e5',
  700: '#1976d2',
  800: '#1565c0',
  900: '#0d47a1',
}

const secondaryColors = {
  50: '#fce4ec',
  100: '#f8bbd0',
  200: '#f48fb1',
  300: '#f06292',
  400: '#ec407a',
  500: '#e91e63',
  600: '#d81b60',
  700: '#c2185b',
  800: '#ad1457',
  900: '#880e4f',
}

const darkGray = {
    100: "#d1d8d8",
    200: "#a3b0b0",
    300: "#768989",
    400: "#486161",
    500: "#1a3a3a",
    600: "#152e2e",
    700: "#102323",
    800: "#0a1717",
    900: "#050c0c"
}

const seaGreen =  {
    100: "#d5e9e2",
    200: "#abd4c4",
    300: "#81bea7",
    400: "#57a989",
    500: "#2d936c",
    600: "#247656",
    700: "#1b5841",
    800: "#123b2b",
    900: "#091d16"
}

const cyan = {
    100: "#cef0f9",
    200: "#9ce0f4",
    300: "#6bd1ee",
    400: "#39c1e9",
    500: "#08b2e3",
    600: "#068eb6",
    700: "#056b88",
    800: "#03475b",
    900: "#02242d"
}

const khaki =  {
    100: "#f1eeea",
    200: "#e3dcd6",
    300: "#d6cbc1",
    400: "#c8b9ad",
    500: "#baa898",
    600: "#95867a",
    700: "#70655b",
    800: "#4a433d",
    900: "#25221e"
}

const red = {
    100: "#f9d8dd",
    200: "#f2b1bc",
    300: "#ec899a",
    400: "#e56279",
    500: "#df3b57",
    600: "#b22f46",
    700: "#862334",
    800: "#591823",
    900: "#2d0c11"
}


const commonBackground = {
  default: '#ebebeb',
  paper: '#ffffff',
};

const darkBackground = {
  default: '#0f172a',
  paper: '#424242',
};

const commonText = {
  primary: '#000000',
  secondary: '#dddddd',
};

const darkText = {
  primary: '#dddddd',
  secondary: '#b0bec5',
};

// Create the theme with conditional logic
const createCustomTheme = (mode) => createTheme({
  palette: {
    mode,
    primary: {
      main: primaryColors[500],
      light: primaryColors[300],
      dark: primaryColors[700],
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryColors[500],
      light: secondaryColors[300],
      dark: secondaryColors[700],
      contrastText: '#fff',
    },
    cyan: { 
        main: cyan[500], 
        light: cyan[300],
        dark: cyan[700],
    },
    darkGray: {
        main: darkGray[500], 
        light: darkGray[300],
        dark: darkGray[700],
    },
    red: {
        main: red[500], 
        light: red[300],
        dark: red[700],
    },  
    khaki: {
        main: khaki[500], 
        light: khaki[300],
        dark: khaki[700],
    },  
    seaGreen: {
        main: seaGreen[500], 
        light: seaGreen[300],
        dark: seaGreen[700],
    }, 
    background: mode === 'light' ? commonBackground : darkBackground,
    text: mode === 'light' ? commonText : darkText,
  },
  typography: {
    fontFamily: '"Intertight", "Roboto", "Helvetica", "Arial", sans-serif',
    
    // color: '#94A3B8 !important',
    h1: {
      fontWeight: 700,
      // color: '#fff',
    },
    h2: {
      fontWeight: 600,
      // color: '#fff',

    },
    h3: {
      fontWeight: 500,
      // color: '#fff',
    },
    h4: {
        fontWeight: 400,
      // color: '#fff',

    },
    h5: {
        fontWeight: 400,
        // color: '#fff',
    },
    body1: {
      fontWeight: 300,
      // color: '#94A3B8',
      lineHeight: '35px',
      textAlign: 'start',

    },
    about: {
      fontWeight: 300,
      // color: '#94A3B8',
      lineHeight: '30px',
      textAlign: 'start',
      fontSize: '16px'
    }
    // Add more typography customizations as needed
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '20px',
          textTransform: 'none',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '16px',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    // Add more component customizations as needed
  },

})

const lightTheme = createCustomTheme('light')
const darkTheme = createCustomTheme('dark')

export { lightTheme, darkTheme }
