import React, { useState, useEffect } from 'react'
import '../../theme/theme.css'
import useMediaQuery from '@mui/material/useMediaQuery'

export default function Cursor({ mode }) {
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [isTouchMode, setIsTouchMode] = useState(false)
  const matches = useMediaQuery('(min-width:600px)')

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isTouchMode) {
        setPosition({
          x: e.clientX + window.scrollX,
          y: e.clientY + window.scrollY,
        })
      }
    }
    const handleTouchStart = () => {
      setIsTouchMode(true)
    }
    const handleMouseDown = () => {
      setIsTouchMode(false)
    }
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('touchstart', handleTouchStart)
    window.addEventListener('mousedown', handleMouseDown)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('touchstart', handleTouchStart)
      window.removeEventListener('mousedown', handleMouseDown)
    }
  }, [isTouchMode])

  return (
    !isTouchMode && matches && (
      <div
        className="cursorStyle"
        style={{
          top: position.y,
          left: position.x,
          background:
            mode === 'dark'
              ? 'radial-gradient(#24365ff7, #121b31f7)'
              : 'radial-gradient(#fff, #fef9ef)',
        }}
      ></div>
    )
  )
}
