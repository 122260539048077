import React from 'react'
import Typography from '@mui/material/Typography'
import Grid  from '@mui/material/Grid2'
import Avatar from '@mui/material/Avatar'

export default function ExperienceInfo({selectedExperience}){
    return (
        <Grid container spacing={2} columns={16}>
            <Grid size={12}  sx={{marginX: 'auto', textAlign: 'center'}}>
                    <img alt='logo' src={selectedExperience?.logo} style={{width: {lg: '50%', xs: '30%'}}}/>
            </Grid>
            <Grid size={8}>
                <Typography variant="h4">{selectedExperience?.Company}</Typography>
                <Typography variant="h5">{selectedExperience?.title}</Typography>
            </Grid>
            <Grid size={8}>
                <Typography>{selectedExperience?.shortDescription}</Typography>
            </Grid>
        </Grid>
    )
}