import React, { StrictMode, useState, useEffect } from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider, CssBaseline } from '@mui/material'
import App from './App'
import { lightTheme, darkTheme } from './theme/theme'

const RootApp = () => {
  const getInitialTheme = () => {
    const savedTheme = localStorage.getItem('theme')
    return savedTheme ? savedTheme : 'dark'
  }

  const [mode, setMode] = useState(getInitialTheme)

  useEffect(() => {
    localStorage.setItem('theme', mode)
  }, [mode])
  
  const toggleTheme = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
  }

  const theme = mode === 'light' ? lightTheme : darkTheme

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App toggleTheme={toggleTheme} mode={mode} />
    </ThemeProvider>
  )
}

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

root.render(
  <StrictMode>
    <RootApp />
  </StrictMode>
)